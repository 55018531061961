const routes = {
  path: "/facturacion",
  component: () => import("./Module.vue"),
  children: [
    {
      path: "/facturas",
      name: "Facturas",
      component: () => import("./views/Facturas.vue"),
      props: (route) => Object.assign({}, route.query, route.params),
      meta: { requiresAuth: true },
    },
    {
      path: "/facturacion",
      name: "Facturacion",
      component: () => import("./views/Facturas.vue"),
      meta: { requiresAuth: true, acceso: "VER_FACTURAS_CONTRATOS" },
    },
    {
      path: "remesas",
      name: "Racturacion",
      component: () => import("./views/Remesas.vue"),
      meta: { requiresAuth: true, acceso: "VER_FACTURAS_CONTRATOS" },
    },
  ],
};

export default routes;
