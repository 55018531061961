import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import es from "vuetify/es5/locale/es";

Vue.use(Vuetify);

const mq = window.matchMedia("(prefers-color-scheme: dark)");
const storedTheme = localStorage.getItem("theme");
const dark = storedTheme === null ? mq.matches : JSON.parse(storedTheme);

export default new Vuetify({
	theme: {
		options: {
			customProperties: true,
		},
		dark,
		themes: {
			light: {
				primary: "#E41270",
				secondary: "#0983BC",
			},
		},
	},
	lang: {
		locales: { es },
		current: "es",
	},
	icons: {
		values: {
			Dual: { component: () => import('@/assets/dualIcon.svg') }
		}
	}
});
