const routes = {
  path: "/sips",
  component: () => import("./Module.vue"),
  children: [
    {
      path: "",
      name: "Sips",
      component: () => import("./views/Sips.vue"),
      meta: { requiresAuth: true, acceso: "VER_CONTRATOS" },
    },
  ],
};

export default routes;
