const routes = {
  path: "/incidencias",
  component: () => import("./Module.vue"),
  children: [
    {
      path: "",
      name: "Incidencias",
      component: () => import("./views/Incidencias.vue"),
      props: (route) => Object.assign({}, route.query, route.params),
      meta: { requiresAuth: true, acceso: "VER_INCIDENCIAS" },
    },
  ],
};

export default routes;
