const routes = {
  path: "/clientes",
  component: () => import("./Module.vue"),
  children: [
    {
      path: "",
      name: "Clientes",
      component: () => import("./views/Clientes.vue"),
      meta: { requiresAuth: true, acceso: "VER_CONTRATOS" },
    },
    {
      path: "morosos",
      name: "MorososClientes",
      component: () => import("./views/MorososClientes.vue"),
      meta: { requiresAuth: true, acceso: "CONTROL_IMPAGADOS" },
    },
    {
      path: "detalles",
      name: "DetallesCliente",
      component: () => import("./views/DetallesCliente.vue"),
      meta: { requiresAuth: true },
    },
  ],
};

export default routes;
