import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store/index.js";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/Resumen.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Login.vue"),
    beforeEnter: (to, from, next) => {
      if (store.getters.isLoggedIn) next({ name: "Home" });
      else next();
    },
  },
  {
    path: "/logout",
    name: "Logout",
    beforeEnter: async (to, from, next) => {
      if (confirm("¿Estás seguro de que quieres cerrar sesión?")) {
        await store.dispatch("logout");
        router.push("/login");
      }
    },
  },
  {
    path: "/documentacion",
    name: "Documentacion",
    component: () => import("@/views/Documentacion.vue"),
    meta: { requiresAuth: true, acceso: "VER_CONTRATOS" },
  },
  {
    path: "/detalles_cups",
    name: "DetallesCups",
    component: () => import("@/views/DetallesCups.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/pruebas",
    name: "Pruebas",
    component: () => import("@/views/Pruebas.vue"),
    meta: { requiresAuth: true },
  },
];

import ContratosModule from "@/modules/contratos/index.js";
import SipsModule from "@/modules/sips/index.js";
import IncidenciasModule from "@/modules/incidencias/index.js";
import ImpagadosModule from "@/modules/impagados/index.js";
import FacturacionModule from "@/modules/facturacion/index.js";
import ClientesModule from "@/modules/clientes/index.js";
import AutofacturacionModule from "@/modules/autofacturacion/index.js";
import ColaboradoresModule from "@/modules/colaboradores/index.js";
import LiquidacionesModule from "@/modules/liquidaciones/index.js";

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    ...routes,
    ...ContratosModule.router,
    IncidenciasModule.router,
    SipsModule.router,
    ImpagadosModule.router,
    FacturacionModule.router,
    ClientesModule.router,
    AutofacturacionModule.router,
    ColaboradoresModule.router,
    LiquidacionesModule.router,
    {
      path: "/*",
      redirect: "/",
    },
  ],
});

import { decode64 } from "@/utils/index.js";

const validarToken = (token) =>
  new Date(JSON.parse(decode64(token)).FechaExpiracion).getTime() >
  new Date().getTime();

router.beforeEach(async (to, from, next) => {
  if (
    to.matched.some((record) => record.meta.requiresAuth) &&
    (store.getters.getToken === null ||
      store.getters.getPermisos === null ||
      !validarToken(store.getters.getToken))
  ) {
    let msg = store.getters.getToken != null ? "La sesión ha expirado" : "";
    await store.dispatch("logout");
    next({ name: "Login", query: { redirect: to.path, msg } });
  } else {
    store.dispatch('refreshToken');
  }

  if (to.matched.some((record) => record.meta.acceso)) {
    const acceso = to.matched.find((record) => record.meta.acceso).meta.acceso;
    if (
      !!(
        store.getters.getDecodedJwtEmpresa.permisos_b &
        store.getters.getPermisos[acceso]
      )
    )
      next();
    else next({ name: "Home" });
  }

  if (to.matched.some((record) => record.meta.soloLocal)) {
    if (process.env.NODE_ENV == "development") next();
    else next({ name: "Home" });
  }
  next();
});


export default router;
