const routes = {
  path: "/colaboradores",
  component: () => import("./Module.vue"),
  children: [
    {
      path: "",
      name: "Colaboradores",
      component: () =>
        import("./views/Colaboradores.vue"),
      meta: { requiresAuth: true, acceso: "VER_COLABORADORES" },
    },
    {
      path: "nuevo",
      name: "NuevoColaborador",
      component: () =>
        import("./views/NuevoColaborador.vue"),
      meta: { requiresAuth: true, acceso: "CONTROL_COLABORADORES" },
    },
  ],
};

export default routes;
