const routes = {
  path: "/liquidaciones",
  component: () => import("./Module.vue"),
  children: [
    {
      path: "",
      name: "Liquidaciones",
      component: () => import("./views/Liquidaciones.vue"),
      meta: { requiresAuth: true, acceso: "VER_FACTURAS_COLABORADORES" },
    },
    {
      path: "nueva",
      name: "NuevaLiquidacion",
      component: () => import("./views/NuevaLiquidacion.vue"),
      props: (route) => Object.assign({}, route.query, route.params),
      meta: { requiresAuth: true, acceso: "CREAR_FACTURA_COLABORADORES" },
    },
    {
      path: ":idUsuario",
      name: "Liquidacion",
      component: () => import("./views/Liquidacion.vue"),
      props: (route) => Object.assign({}, route.query, route.params),
      meta: { requiresAuth: true, acceso: "VER_FACTURAS_COLABORADORES" },
    },
  ],
};

export default routes;
