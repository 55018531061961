<template>
	<v-app>
		<v-navigation-drawer
			:mini-variant="menuShow"
			:color="$vuetify.theme.dark ? '#1E1E1E' : 'primary'"
			app
			:disable-route-watcher="true"
			inset
			v-if="$store.getters.isLoggedIn && themeLoaded"
			permanent
			touchless
		>
			<div class="d-flex">
				<v-avatar tile :size="!menuShow ? 200 : 60" class="pa-4 mx-auto" :key="theme">
					<v-img
						v-if="!menuShow"
						contain
						:src="`./img/logo_blanco_${$store.getters.getNombreEmpresa}.png`"
					></v-img>
					<v-img v-else contain :src="`./img/fav_${$store.getters.getNombreEmpresa}.png`"></v-img>
				</v-avatar>
			</div>

			<tabs-menu :menu="menu" />

			<template v-if="development" v-slot:append>
				<div class="d-flex align-end justify-center">
					<cambiar-entorno v-if="$root.acceso('VER_CONTRATOS')" />
				</div>
			</template>
		</v-navigation-drawer>

		<v-app-bar app v-if="$store.getters.isLoggedIn && themeLoaded">
			<v-btn @click="menuShow = !menuShow" icon>
				<v-icon v-text="'mdi-menu'" />
			</v-btn>
			<v-toolbar-title>{{ [portal, $store.getters.getNombreEmpresa.toUpperCase()].join(' ') }}</v-toolbar-title>

			<v-spacer />

			<v-btn icon @click="changeTheme">
				<v-icon v-if="$vuetify.theme.dark">mdi-white-balance-sunny</v-icon>
				<v-icon v-else>mdi-weather-night</v-icon>
			</v-btn>

			<alertas-incidencias v-if="$root.acceso('VER_INCIDENCIAS')" :key="$route.fullPath"></alertas-incidencias>

			<v-tooltip bottom>
				<template v-slot:activator="{ on, attrs }">
					<v-btn v-on="on" v-bind="attrs" small text @click.stop="logout" :icon="$vuetify.breakpoint.mobile" color="error">
						<span v-show="!$vuetify.breakpoint.mobile">Cerrar sesion</span>
						<v-icon :right="!$vuetify.breakpoint.mobile">mdi-logout</v-icon>
					</v-btn>
				</template>
				{{ $store.getters.getDecodedJwtEmpresa.nombre }}
			</v-tooltip>
		</v-app-bar>

		<v-main v-show="themeLoaded" :style="`background: ${!$vuetify.theme.dark ? '#f3f3f3' : ''}; position: relative `">
			<v-container fluid>
				<!-- <v-fade-transition mode="out-in"> -->
				<router-view :key="$route.fullPath + $store.getters.getEntorno" />
				<!-- </v-fade-transition> -->
			</v-container>
			<v-snackbar v-model="snack" timeout="4000" color="primary" type="error">
				{{ msg }}
			</v-snackbar>

			<div class="d-flex" style="position: fixed; right: 25px; bottom: 25px; gap: 15px">

				<!-- <QuickClipboard /> -->

			<v-speed-dial
				v-if="(development) && themeLoaded"
			>
				<template v-slot:activator>
					<v-btn small color="primary" dark fab>
						<v-icon v-text="'mdi-palette'" />
					</v-btn>
				</template>
				<v-tooltip
					left
					:key="theme.name"
					:color="theme.light.secondary"
					v-for="theme in themes.filter(({ name }) => name != theme)"
				>
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							:key="theme.name"
							:color="theme.light.primary"
							@click="setTheme(theme.name)"
							dark
							fab
							small
							v-text="theme.name[0]"
							v-on="on"
							v-bind="attrs"
						/>
					</template>
					{{ theme.name.toUpperCase() }}
				</v-tooltip>
			</v-speed-dial>

			</div>

			
		</v-main>

	</v-app>
</template>

<script>
// import axios from "redaxios";
import { timeAgo, decode64, parseDate } from "@/utils/index.js";

export default {
	name: "App",
	components: {
		AlertasIncidencias: () => import("@/modules/incidencias/components/AlertasIncidencias.vue"),
		CambiarEntorno: () => import("@/components/CambiarEntorno.vue"),
		TabsMenu: () => import("@/components/TabsMenu.vue"),
		// QuickClipboard: () => import("@/components/QuickClipboard.vue"),
	},
	metaInfo() {
		return {
			title: `${this.portal} | ${this.$store.getters.getNombreEmpresa}`,
			link: [
				{
					rel: "icon",
					href: `./img/fav_${this.$store.getters.getNombreEmpresa}.png`,
				},
			],
		};
	},
	data: () => ({
		menuShow: false,
		nombreAgente: "",
		themeLoaded: false,
		msg: "",
		snack: false,
		development: process.env.NODE_ENV == "development",
		theme: null,
		themes: [],
		portal: ((s = window.location.host.replaceAll(/www./g, "").split(".")[0]) => s && s[0] && s[0].toUpperCase() + s.slice(1))(),
		menu: [
				{ url: "/", texto: "Resumen", icon: "mdi-home" },
				{ url: "/sips", texto: "Sips", icon: "mdi-magnify", acceso: 'VER_CONTRATOS' },
				{ url: "/contratos", texto: "Contratos", icon: "mdi-file-document-edit-outline", acceso: 'VER_CONTRATOS' },
				{ url: "/borradores", texto: "Borradores", icon: "mdi-eraser", acceso: 'VER_CONTRATOS' },
				{ url: "/clientes", texto: "Clientes", icon: "mdi-account-group", acceso: 'VER_CONTRATOS' },
				{ url: "/clientes/morosos", texto: "Histórico morosos", icon: "mdi-gavel", acceso: 'CONTROL_IMPAGADOS' },
				{ url: "/documentacion", texto: "Documentacion", icon: "mdi-folder", acceso: 'VER_CONTRATOS' },
				{ url: "/incidencias", texto: "Incidencias", icon: "mdi-alert-circle", acceso: 'VER_INCIDENCIAS', },
				{ url: "/facturacion", texto: "Facturas clientes", icon: "mdi-currency-eur", acceso: 'VER_FACTURAS_CONTRATOS', },
				{ url: "/colaboradores", texto: "Colaboradores", icon: "mdi-account-tie", acceso: 'VER_COLABORADORES', },
				{ url: "/impagados/control", texto: "Control impagados", icon: "mdi-currency-eur-off", acceso: 'CONTROL_IMPAGADOS', },
				// { url: "/impagados/tareas", texto: "Tareas Impagados", icon: "mdi-calendar-check-outline", acceso: 'CONTROL_IMPAGADOS' },
				{ url: "/impagados/timeline", texto: "Tareas Impagados", icon: "mdi-calendar-check-outline", acceso: 'CONTROL_IMPAGADOS' },
				{ url: "/impagados/resumen", texto: "Resumen Impagados", icon: "mdi-cash-remove", acceso: 'ESTADISTICAS_IMPAGADOS' },
				{ url: "/liquidaciones", texto: "Liquidaciones colaboradores", icon: "mdi-archive-outline", acceso: 'VER_FACTURAS_COLABORADORES', },
				{ url: "/pruebas", texto: "Pruebas", icon: "mdi-test-tube", acceso: 'DEV', },
			]
	}),
	computed: {
		isIdle() {
			return this.isAppIdle;
		},
	},
	async beforeCreate() {
		let dominio =
			!/(\d{1,3}\.?){4}/.test(window.location.host) ?
				window.location.host.replaceAll(/www./g, "").split(".")[1] || 'alumbraenergia' :
				"alumbraenergia";

		await this.$store.dispatch("setNombreEmpresa", dominio);
		let { data: themes } = await axios({ url: "/themes.json" });
		let aux = {};
		Object.values(themes.themes).forEach(
			(t) => (aux[t.name] = t.light.primary)
		);
		await this.$store.dispatch("setColoresEmpresas", aux);
		this.themes = Object.values(themes.themes);
		let theme = themes.themes[dominio];
		this.theme = theme.name;
		Object.keys(theme.light).forEach((key) => {
			this.$vuetify.theme.themes.light[key] = theme.light[key];
			this.$vuetify.theme.themes.dark[key] = theme.light[key];
		});
		this.$vuetify.theme.themes.name = theme.name;
		this.themeLoaded = true;
	},
	methods: {
		keyevent(e) {
			console.log(e)
		},
		timeAgo, decode64, parseDate,
		setTheme(theme) {
			return new Promise(async (resolve) => {
				this.themeLoaded = false;
				if (typeof theme != "object")
					theme = this.themes.find(({ name }) => name == theme);
				this.theme = theme.name;
				Object.keys(theme.light).forEach((key) => {
					this.$vuetify.theme.themes.light[key] = theme.light[key];
					this.$vuetify.theme.themes.dark[key] = theme.light[key];
				});
				this.$vuetify.theme.themes.name = theme.name;
				this.themeLoaded = true;
				await this.$store.dispatch("setNombreEmpresa", theme.name);
				resolve();
			});
		},
		logout() {
			confirm("¿Estás seguro de que quieres cerrar sesión?")
				? this.$store
					.dispatch("logout")
					.then(() => this.$router.push({ name: "Login" }))
				: null;
		},
		changeTheme() {
			this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
			localStorage.setItem('theme', this.$vuetify.theme.dark);
		}
	},
	watch: {
		isIdle(newVal) {
			if (newVal && this.$store.getters.isLoggedIn) {
				this.$store.dispatch("logout");
				this.$router.push({
					name: "Login",
					query: {
						msg: "Se ha cerrado la sesión por inactividad",
						redirect: this.$route.fullPath,
					},
				});
			}
		},
	},
	mounted() {
		this.$root.$on("snack", (msg) => {
			this.snack = false;
			this.snack = true;
			this.msg = msg;
		});

		window.cambiarEntorno = () => {
			this.$store.dispatch("cambiarEntorno")
		}

	},
};
</script>

<style lang="scss">
.border-l {
	border-left: 5px solid transparent;
}
.border-l-white {
	border-left: 5px;
}
.border-l {
	border-left: 5px solid transparent;
}
.border-l-white {
	border-left: 5px solid white;
}
.active-tab {
	background-color: #00000036;
}

*::-webkit-scrollbar {
	width: 10px;
	height: 10px;
}
*::-webkit-scrollbar-thumb {
	border-radius: 20px;
	background: var(--v-primary-base);
}
*.theme--dark ::-webkit-scrollbar-track {
	border-radius: 20px;
	background: #121212;
}
*::-webkit-scrollbar-track {
	border-radius: 20px;
	background: #bebebe;
}

.v-chip--active.theme--dark {
	border-style: unset !important;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
	background: #f1f1f1 !important;
}
.theme--dark.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
	background: #272727 !important;
}

.v-data-table.fixed-checkbox
	table
	> tbody
	> tr
	> td:nth-child(1):not(.remove-filter),
.v-data-table.fixed-checkbox
	table
	> thead
	> tr
	> th:nth-child(1):not(.remove-filter) {
	// position: sticky !important;
	// position: -webkit-sticky !important;
	// left: 0;
	position: sticky;
	left: 0;
	z-index: 4;
	background: inherit;
}

.v-data-table.theme--light
	table
	> tbody
	> tr:not(.v-data-table__selected):not(:hover)
	> td:nth-child(1):not(.remove-filter),
.v-data-table.theme--light
	table
	> thead
	> tr:not(.v-data-table__selected):not(:hover)
	> th:nth-child(1):not(.remove-filter) {
	background: #ffffff;
}

.v-data-table.theme--dark
	table
	> tbody
	> tr:not(.v-data-table__selected):not(:hover)
	> td:nth-child(1):not(.remove-filter),
.v-data-table.theme--dark
	table
	> thead
	> tr:not(.v-data-table__selected):not(:hover)
	> th:nth-child(1):not(.remove-filter) {
	background: #1e1e1e;
}
</style>