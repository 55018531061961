const routes = {
  path: "/impagados",
  component: () => import("./Module.vue"),
  redirect: { name: "ControlImpagados" },
  children: [
    {
      path: "control",
      name: "ControlImpagados",
      component: () => import("./views/ImpagadosControl.vue"),
      props: (route) => Object.assign({}, route.query, route.params),
      meta: { requiresAuth: true, acceso: "CONTROL_IMPAGADOS" },
    },
    {
      path: "resumen",
      name: "ImpagadosDeuda",
      component: () => import("./views/ImpagadosResumen.vue"),
      meta: { requiresAuth: true, acceso: "ESTADISTICAS_IMPAGADOS" },
    },
    {
      path: "informe",
      name: "ImpagadosInformes",
      component: () => import("./views/ImpagadosInforme.vue"),
      meta: { requiresAuth: true, acceso: "ESTADISTICAS_IMPAGADOS" },
    },
    {
      path: "mensual",
      name: "ImpagadosMensual",
      component: () => import("./views/ImpagadosMensual.vue"),
      meta: { requiresAuth: true, acceso: "ESTADISTICAS_IMPAGADOS" },
    },
    // {
    //   path: "tareas",
    //   name: "TareasImpagados",
    //   component: () => import("./views/TareasImpagados.vue"),
    //   meta: { requiresAuth: true, acceso: "CONTROL_IMPAGADOS" },
    // },
    {
      path: "timeline",
      name: "ImpagadosTimeline",
      component: () => import("./views/ImpagadosTimeline.vue"),
      meta: { requiresAuth: true, acceso: "CONTROL_IMPAGADOS" },
    },
  ],
};

export default routes;
