import Vue from "vue";
import IdleVue from "idle-vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

import PortalVue from "portal-vue";
Vue.use(PortalVue);

import VueMeta from "vue-meta";
Vue.use(VueMeta);

import AsyncComputed from "vue-async-computed";
Vue.use(AsyncComputed);

Vue.config.productionTip = false;
Vue.config.errorHandler = async (error) => {
	if (
		store.getters.isLoggedIn &&
		error.response &&
		(error.response.status == 401 || error.response.status == 403)
	) {
		await store.dispatch("logout");
		router.push({
			name: "Login",
			query: {
				msg: "La sesión ha expirado",
				redirect: router.app._route.fullPath,
			},
		});
	} else {
		console.error(error);
	}
};

import axios from "axios";
let IdAgente = null;
if (store.getters.getDatosEmpresa)
 IdAgente = store.getters.getDatosEmpresa.iss.IdAgente;

window.axios = axios.create({
	headers: { Authorization: store.getters.getToken },
	params: { token: store.getters.getJwtEmpresa, entorno: store.getters.getEntorno, IdAgente },
	data: { token: store.getters.getJwtEmpresa, entorno: store.getters.getEntorno, IdAgente },
});

// import JsonCSV from "vue-json-csv";
// Vue.component("downloadCsv", JsonCSV);

Vue.config.productionTip = false;

const eventsHub = new Vue();
Vue.use(IdleVue, {
	eventEmitter: eventsHub,
	idleTime: 7200000,
	store,
	startAtIdle: false,
});

Vue.filter("eur", v => parseFloat(v).toFixed(2).toLocaleString() + ' €')
Vue.filter("phone", v => v ? [ String(v).slice(0, 3), String(v).slice(3, 5), String(v).slice(5, 7), String(v).slice(7, 9) ].join(' ') : '' )

new Vue({
	router,
	store,
	vuetify,
	render: (h) => h(App),
	methods: {
		acceso: (value) => !!(
				store.getters.getPermisos &&
				store.getters.getPermisos[value] &
				Number(store.getters.getDecodedJwtEmpresa.permisos_b)
			)
	},
}).$mount("#app");