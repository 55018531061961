import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

import { encode64, decode64, processToken } from "@/utils/index.js";
// import axios from "redaxios";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    token: localStorage.getItem("token") || null,
    datosEmpresa: JSON.stringify(localStorage.getItem("datosEmpresa")) || null,
    nombreEmpresa: "",
    jwtEmpresa: localStorage.getItem("jwtEmpresa") || null,
    coloresEmpresas: null,
    alerts: 0,
    entorno: localStorage.getItem("entorno") || null,
    idEmpresa: localStorage.getItem("idEmpresa") || null,
    permisos: JSON.parse(localStorage.getItem("permisos")) || null,

    refreshLock: Date.now(),
  },
  getters: {
    getToken: (state) => state.token,
    isLoggedIn: (state) => !!state.token,
    getDatosEmpresa: (state) =>
      !!state.datosEmpresa && state.datosEmpresa != "null"
        ? processToken(state.datosEmpresa)
        : null,
    getNombreEmpresa: (state) => state.nombreEmpresa || "",
    getJwtEmpresa: (state) => state.jwtEmpresa,
    getDecodedJwtEmpresa: (state) =>
      !state.jwtEmpresa || JSON.parse(decode64(state.jwtEmpresa.split(".")[1])),
    isAdmin: (state, getters) =>
      getters.isLoggedIn
        ? JSON.parse(decode64(state.jwtEmpresa.split(".")[1])).permisos == 1
        : false,
    getColoresEmpresas: (state) => state.coloresEmpresas || null,
    getAlerts: (state) => state.alerts || 0,
    getIdEmpresa: (state) => state.idEmpresa,
    getEntorno: (state) => state.entorno,
    getPermisos: (state) => state.permisos,
  },
  mutations: {
    login(state, token) {
      return new Promise((resolve) => {
        localStorage.setItem("token", token);
        state.token = token;
        resolve();
      });
    },
    datosEmpresa(state, { datosEmpresa, idEmpresa, CodSectorEmpresarial }) {
      return new Promise((resolve) => {
        localStorage.setItem("datosEmpresa", datosEmpresa);
        localStorage.setItem("idEmpresa", idEmpresa);
        localStorage.setItem(
          "entorno",
          CodSectorEmpresarial == 2 ? "Luz" : "Gas"
        );
        state.datosEmpresa = datosEmpresa;
        state.idEmpresa = idEmpresa;
        state.entorno = CodSectorEmpresarial == 2 ? "Luz" : "Gas";
        resolve();
      });
    },
    logout(state) {
      return new Promise((resolve) => {
        state.token = null;
        state.datosEmpresa = null;
        state.jwtEmpresa = null;
        resolve();
      });
    },
    setNombreEmpresa(state, domain) {
      return new Promise((resolve) => {
        state.nombreEmpresa = domain;
        resolve();
      });
    },
    jwtEmpresa(state, token) {
      return new Promise((resolve) => {
        localStorage.setItem("jwtEmpresa", token);
        state.jwtEmpresa = token;
        resolve();
      });
    },
    setColoresEmpresas(state, obj) {
      return new Promise(async (resolve) => {
        state.coloresEmpresas = obj;
        resolve();
      });
    },
    setAlerts(state, alerts) {
      return new Promise(async (resolve) => {
        state.alerts = alerts;
        resolve();
      });
    },
    setPermisos(state, per) {
      return new Promise(async (resolve) => {
        localStorage.setItem("permisos", JSON.stringify(per));
        state.permisos = per;
        resolve();
      });
    },
  },
  actions: {
    login(
      { commit, getters },
      { user, pass, idEmpresa, CodSectorEmpresarial }
    ) {
      return new Promise(async (resolve, reject) => {
        try {
          const entorno = CodSectorEmpresarial == 2 ? "Luz" : "Gas";

          let { data: AUTH_TOKEN } = await axios({
            method: "GET",
            url: `${process.env.VUE_APP_API_URL}/Usuario/Token`,
            headers: {
              user: encode64(user),
              password: encode64(pass),
            },
          });

          if (AUTH_TOKEN == "No se encontró el usuario en la base de datos") {
            throw new Error("No se encontró el usuario en la base de datos");
          }
          let { data: datosEmpresa } = await axios({
            headers: { Authorization: AUTH_TOKEN },
            method: "POST",
            url: `${
              process.env.VUE_APP_API_URL
            }/Usuario/LoginPost/Login/${encode64(user)}/Password/${encode64(
              pass
            )}`,
          });

          const { IdAgente } = processToken(datosEmpresa).iss;

          if (!processToken(datosEmpresa).iss.IdAgente)
            throw new Error("El usuario no está activado");

          let { data: jwt } = await axios({
            method: "POST",
            url: `${process.env.VUE_APP_OUR_API_URL}/login.php`,
            data: { usuario: user, contraseña: pass, entorno },
          });

          let { data: permisos } = await axios({
            method: "GET",
            url: `${process.env.VUE_APP_OUR_API_URL}/permisos.php`,
          });

          let per = {};
          permisos.forEach(
            ({ permiso, valor }) => (per[permiso] = Number(valor))
          );

          let empresa = JSON.parse(decode64(jwt.token.split(".")[1])).empresa;
          if (empresa != getters.getNombreEmpresa) {
            await commit("logout");
            reject();
            return;
          }

          await Promise.all([
            commit("login", AUTH_TOKEN),
            commit("datosEmpresa", { datosEmpresa, idEmpresa, CodSectorEmpresarial, }),
            commit("jwtEmpresa", jwt.token),
            commit("setPermisos", per),
          ]);

          window.axios = axios.create({
            headers: { Authorization: AUTH_TOKEN },
            params: { token: store.getters.getJwtEmpresa, entorno, IdAgente, },
            data: { token: store.getters.getJwtEmpresa, entorno, IdAgente, },
          });

          resolve();
        } catch (e) {
          console.error(e);
          await commit("logout");
          reject(e);
          return;
        }
      });
    },
    logout({ commit }) {
      return new Promise((resolve, reject) => {
        localStorage.removeItem("token");
        localStorage.removeItem("datosEmpresa");
        localStorage.removeItem("jwtEmpresa");
        localStorage.removeItem("idEmpresa");
        localStorage.removeItem("permisos");
        commit("logout");
        resolve();
      });
    },
    refreshToken({ commit, getters, state }) {
      return new Promise(async (resolve) => {
        if (Date.now() < state.refreshLock || !getters.getJwtEmpresa) {
          resolve();
          return;
        }

        const { data } = await axios({
          method: "GET",
          url: `${process.env.VUE_APP_OUR_API_URL}/refresh_token.php`,
          params: {
            token: getters.getJwtEmpresa,
          },
        });
        let per = {};
        data.permisos.forEach(
          ({ permiso, valor }) => (per[permiso] = Number(valor))
        );

        state.refreshLock = Date.now() + 1000 * 60 * 10;

        resolve(
          await Promise.all([
            commit("jwtEmpresa", data.token),
            commit("setPermisos", per),
          ])
        );
      });
    },
    setNombreEmpresa({ commit }, domain) {
      return new Promise(async (resolve) => {
        await commit("setNombreEmpresa", domain);
        resolve();
      });
    },
    setColoresEmpresas({ commit }, obj) {
      return new Promise(async (resolve) => {
        await commit("setColoresEmpresas", obj);
        resolve();
      });
    },
    setAlerts({ commit }, alerts) {
      return new Promise(async (resolve) => {
        await commit("setAlerts", alerts);
        resolve();
      });
    },
    cambiarEntorno({ commit, getters }) {
      return new Promise(async (resolve, reject) => {
        const datosEmpresa = localStorage.getItem("datosEmpresa");

        const { data: entornoCuenta } = await axios({
          url: `${process.env.VUE_APP_OUR_API_URL}/entornoUsuario.php`,
          method: "POST",
          data: {
            idUsuario: getters.getDecodedJwtEmpresa.idUsuario,
          },
        });

        if (!Number(entornoCuenta.dual)) return;

        const { IdEmpresa: idEmpresa, CodSectorEmpresarial } = processToken(
          datosEmpresa
        ).iss.Empresas.find(
          (empresa) => empresa.IdEmpresa != localStorage.getItem("idEmpresa")
        );

        commit("datosEmpresa", {
          datosEmpresa,
          idEmpresa,
          CodSectorEmpresarial,
        });

        const { IdAgente } = store.getters.getDatosEmpresa.iss;

        axios = axios.create({
          headers: { Authorization: store.getters.getToken },
          params: {
            token: store.getters.getJwtEmpresa,
            entorno: store.getters.getEntorno,
            IdAgente,
          },
          data: {
            token: store.getters.getJwtEmpresa,
            entorno: store.getters.getEntorno,
            IdAgente,
          },
        });

        resolve();
      });
    },
    setPermisos({ commit }, permisos) {
      return new Promise(async (resolve) => {
        await commit("setPermisos", permisos);
        resolve();
      });
    },
  },
  modules: {},
});

export default store;
