const routes = {
  path: "/autofacturacion",
  component: () => import("./Module.vue"),
  children: [
    {
      path: "",
      name: "Autofacturas",
      component: () => import("./views/Facturas.vue"),
      meta: { requiresAuth: true, acceso: "VER_FACTURAS_COLABORADORES" },
    },
    {
      path: "autofacturar",
      name: "Autofacturar",
      component: () => import("./views/ContratosSinFacturar.vue"),
      meta: { requiresAuth: true, acceso: "CREAR_FACTURA_COLABORADORES" },
    },
  ],
};

export default routes;
