const routes = [
  {
    path: "/contratos",
    component: () => import("./Module.vue"),
    children: [
      {
        path: "",
        name: "Contratos",
        component: () => import("./views/Contratos.vue"),
        meta: { requiresAuth: true, acceso: "VER_CONTRATOS" },
      },
      {
        path: "detalles",
        name: "DetallesContrato",
        component: () => import("./views/DetallesContrato.vue"),
        props: (route) => Object.assign({}, route.query, route.params),
        meta: { requiresAuth: true },
      },
      {
        path: "calculadora_comisiones",
        name: "CalculadoraComisiones",
        component: () => import("./views/CalculadoraComisiones.vue"),
        props: (route) => Object.assign({}, route.query, route.params),
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: "/borradores",
    component: () => import("./Module.vue"),
    children: [
      {
        path: "",
        name: "Borradores",
        component: () => import("./views/Borradores.vue"),
        meta: { requiresAuth: true, acceso: "VER_CONTRATOS" },
      },
      {
        path: "cargar_contrato",
        name: "CargarContrato",
        component: () => import(`./views/CargarContrato.vue`),
        meta: { requiresAuth: true, acceso: "CREAR_CONTRATO" },
      },
      {
        path: "detalles",
        name: "DetallesBorrador",
        component: () => import("./views/DetallesBorrador.vue"),
        meta: { requiresAuth: true, acceso: "VER_CONTRATOS" },
      },
    ],
  },
];

export default routes;
